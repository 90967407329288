import React from 'react'
import { FaHandshake } from 'react-icons/fa';
import { BiUser } from 'react-icons/bi';
import { BsClock } from 'react-icons/bs';
import PrimaryButton from './boton/PrimaryButton';



function CounterUp({ Imgfirst, ImgSecond, slogans, years, miles, location, imgback, text }) {


    const BussinesVals = [
        {
            icon: <FaHandshake fontSize={50} />,
            frase: <h4 className='text-white text-2xl font-extrabold'>{years} Years Experience</h4>
        },
        {
            icon: <BiUser fontSize={50} />,
            frase: <h4 className='text-white text-2xl font-extrabold'>100 % Professionals</h4>
        },
        {
            icon: <BsClock fontSize={50} />,
            frase: <h4 className='text-white text-2xl font-extrabold'>100 %  On Time</h4>

        }

    ]

    return (
        <div className='w-full h-auto flex flex-col justify-center items-center mt-36'>
            <div className='w-full lg:h-[500px] h-auto relative before:z-10 before:absolute before:w-full before:h-full before:bg-gradient-to-r before:bg-black/100 before:to-bg-black/before:bg-black/100 before:opacity-90 lg:-mt-20 mt-4'>
                <div
                    className='w-full h-full flex lg:flex-row flex-col items-center justify-center'
                    style={{ background: `url("https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/lienas.jpg?alt=media&token=66055b37-1dcc-4cef-b601-7954a321678e")`, backgroundPosition: "center", backgroundSize: "cover" }}
                >
                    <div className='w-[90%] h-auto py-4 z-50 flex lg:flex-row flex-col'>
                        <div className='lg:w-1/2 w-full flex flex-col items-center md:items-start text-center lg:text-start'>
                            <div className='w-full h-auto'>
                                <div className='my-5 text-white'>
                                    <h1 className='text-5xl font-black'>{slogans}</h1>
                                    <p className='text-white'>{text}</p>
                                </div>

                            </div>

                            <div className='w-full'>
                                <PrimaryButton/>
                            </div>
                        </div>

                        <div className='lg:w-1/2 w-full items-center justify-center flex flex-wrap lg:flex-nowrap gap-4 mt-8 lg:mt-0'>
                            {
                                BussinesVals.map((item, index) => {
                                    return (
                                        <div key={index} className='flex flex-col items-center justify-center hover:scale-105 w-[160px]'>
                                            <div className='w-[150px] h-[150px] bg3 rounded-3xl flex items-center justify-center mb-2 text-black transition duration-500 transform hover:-scale-x-100'>
                                                {item.icon}
                                            </div>
                                            <div className='w-full h-[30%] text-center'>
                                                {item.frase}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
export default CounterUp
